<template>
  <video-card-vertical
    v-if="isList"
    :video="video"
    @clicked="(vdo) => handleClick(vdo)"
  />
  <video-card-horizontal
    v-else
    :video="video"
    @clicked="(vdo) => handleClick(vdo)"
  />
</template>

<script>
import VideoCardVertical from "./VideoCardVertical.vue";
import VideoCardHorizontal from "./VideoCardHorizontal.vue";
export default {
  props: ["video", "isList"],
  components: { VideoCardHorizontal, VideoCardVertical },
  methods: {
    handleClick() {
      this.$emit("clicked", this.video);
    },
  },
};
</script>

