<template>
  <v-card color="transparent" class="ratio-4x3" elevation="0">
    <v-img
      @click="handleClick"
      class="ratio-16x10 rounded"
      :src="video.thumbnail"
    >
      <div class="video-overlay">
        <span v-if="video.featured" class="anim-shine top-left">
          <v-icon small color="orange">mdi-star</v-icon>
        </span>
        <!-- <span class="top-right"></span> -->
        <span small class="bottom-right small" v-if="video.duration">
          {{ video.duration.toString().fancyTime() }}
        </span>
        <span class="bottom-left">
          <!-- <v-avatar contain size="20">
            <v-img :src="video.user.avatar"></v-img>
          </v-avatar>
          <span class="caption">{{ video.user.display_name }}</span> -->
        </span>
        <v-btn small color="rgba(255,0,0,.7)" class="white--text">
          <v-icon>mdi-play</v-icon>
        </v-btn>
      </div>
    </v-img>
    <v-list class="my-0 py-0" color="transparent">
      <v-list-item class="px-1">
        <v-list-item-avatar
          @click="
            $router.push({
              name: 'profile',
              params: { id: video.user.username },
            })
          "
          class="px-0 mr-2"
        >
          <v-img :src="video.user.avatar"></v-img>
        </v-list-item-avatar>

        <v-list-item-content>
          <div class="video-title">
            {{
              isHindi
                ? video.title_hi
                  ? video.title_hi
                  : video.title
                : video.title
            }}
          </div>
          <v-list-item-subtitle class="caption">
            <span
              @click="
                $router.push({
                  name: 'profile',
                  params: { id: video.user.username },
                })
              "
            >
              {{ video.user.display_name }}
            </span>
            <span>•</span>
            {{ video.views ? video.views : 0 }} views
            <!-- <span>•</span> -->
            <!-- {{ video.created_at | fromNow }} -->
          </v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action>
          <VideoActions v-if="video" :video="video" />
        </v-list-item-action>
      </v-list-item>
    </v-list>
  </v-card>
</template>

<script>
import VideoActions from "@/app/ugcp/modules/VideoActions";
export default {
  props: ["video"],
  components: { VideoActions },
  methods: {
    handleClick() {
      this.$emit("clicked", this.video);
    },
  },
};
</script>

<style scoped>
.small {
  font-size: 0.7em;
}
.video-overlay {
  background: rgba(0, 0, 0, 0);
  color: #fff;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  contain: content;
}
.top-left {
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  padding: 0.1em 0.3em;
  border-radius: 0 0 0.3em 0;
}
.top-right {
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  right: 0;
  padding: 0.1em 0.3em;
  border-radius: 0 0 0 0.3em;
}
.bottom-left {
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  bottom: 0;
  left: 0;
  padding: 0.1em 0.3em;
  border-radius: 0 0.3em 0 0;
}
.bottom-right {
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  bottom: 0;
  right: 0;
  padding: 0.1em 0.3em;
  border-radius: 0.3em 0 0 0;
}
.ratio-16x10 {
  aspect-ratio: 16 / 9;
  background: url("/img/icons/android-chrome-192x192.png") rgb(255, 255, 255);
  background-size: fill;
  object-fit: fill;
  background-position: center;
  background-repeat: no-repeat;
}
.ratio-4x3 {
  aspect-ratio: 4/3.2;
  background-size: fill;
  object-fit: fill;
  background-position: center;
  background-repeat: no-repeat;
  overflow: hidden;
  text-overflow: ellipsis;
}
.video-title {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
@media only screen and (min-width: 768px) {
  /* Change Resolutions Here */
  .video-title {
    font-size: 0.85em;
    line-height: 1.3em;
  }
}
@media only screen and (max-width: 768px) {
  .video-title {
    font-size: 0.85em;
    line-height: 1.3em;
  }
}
</style>

