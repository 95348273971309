<template>
  <v-bottom-sheet v-model="sheet">
    <template v-slot:activator="{ on, attrs }">
      <v-btn icon v-bind="attrs" v-on="on" text elevation="0">
        <v-icon>mdi-dots-vertical</v-icon>
      </v-btn>
    </template>
    <v-sheet width="100vw" class="">
      <v-progress-linear v-if="loading" indeterminate />
      <v-list nav dense>
        <v-subheader>
          {{
            isHindi
              ? video.title_hi
                ? video.title_hi
                : video.title
              : video.title
          }}
        </v-subheader>
        <v-list-item
          v-for="item in listItems"
          :key="item.title"
          @click="item.action"
        >
          <v-list-item-avatar>
            <v-avatar size="32px" tile>
              <v-icon>{{ item.icon }}</v-icon>
            </v-avatar>
          </v-list-item-avatar>
          <v-list-item-title>{{
            isHindi ? (item.title_hi ? item.title_hi : item.title) : item.title
          }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-sheet>
    <v-dialog width="500" v-model="dialogReport">
      <v-list dense>
        <v-subheader>
          {{ $lang("report") }}:
          {{
            isHindi
              ? video.title_hi
                ? video.title_hi
                : video.title
              : video.title
          }}
        </v-subheader>
        <v-divider></v-divider>

        <v-list-item
          v-for="item in listReportItems"
          :key="item.title"
          @click="reportVideoDialog(item)"
        >
          <v-list-item-title>{{
            isHindi ? (item.title_hi ? item.title_hi : item.title) : item.title
          }}</v-list-item-title>
        </v-list-item>
        <v-divider></v-divider>
        <v-list-item>
          <v-spacer></v-spacer>
          <v-btn @click="dialogReport = false" text>{{
            $lang("cancel")
          }}</v-btn>
        </v-list-item>
      </v-list>
    </v-dialog>
    <ConfirmDialog
      :show="dialogBlock"
      @result="blockVideo"
      @changed="(val) => (dialogBlock = val)"
      :title="`block ${this.video.title}`"
      :title_hi="`ब्लॉक ${this.video.title_hi}`"
      text="You will no longer see this video."
      text_hi="अब से आप यह वीडियो नहीं देखेंगे।"
      :btnOK="$lang('block')"
    />

    <ConfirmDialog
      :show="dialogReportConfirm"
      @result="
        (trueFalse) => {
          if (trueFalse) {
            reportVideo(
              dialogReportConfirmData.reason,
              dialogReportConfirmData.url
            );
          }
        }
      "
      @changed="(val) => (dialogReportConfirm = val)"
      :title="dialogReportConfirmData.title"
      :title_hi="dialogReportConfirmData.title_hi"
      :text="dialogReportConfirmData.text"
      :text_hi="dialogReportConfirmData.text_hi"
      :btnOK="dialogReportConfirmData.btnOK"
      :btnOK_hi="dialogReportConfirmData.btnOK_hi"
      :btnCancel="dialogReportConfirmData.btnCancel"
      :btnCancel_hi="dialogReportConfirmData.btnCancel_hi"
      description="Choosing the correct reason speed up report process. Learn about our <a target='_blank' href='https://vikarn.com/community-guidelines'>community guidelines</a>."
      description_hi="सही कारण चुनने से रिपोर्ट प्रक्रिया में तेजी आती है। हमारे <a target='_blank' href='https://vikarn.com/community-guidelines/hindi'>समुदाय दिशानिर्देशों</a> के बारे में जानें।"
    />
  </v-bottom-sheet>
</template>

<script>
import { mapGetters } from "vuex";

import { axios } from "@/plugins/axios";
import localforage from "@/plugins/localforage";
import ConfirmDialog from "./ConfirmDialog.vue";
import { Browser } from "@capacitor/browser";
import listReportItems from "./listReportItems";
export default {
  name: "video-actions",
  props: ["video"],
  components: { ConfirmDialog },
  data() {
    return {
      loading: false,
      sheet: null,
      dialogReportConfirm: null,
      dialogReport: null,
      dialogBlock: false,
      dialogReportConfirmData: {
        title: "What can I do if I see something I don't like on Vikarn?",
        title_hi: "",
        text: ``,
        text_hi: "",
        reason: "no-reason",
        btnOK: "Report",
        btnOK_hi: "रिपोर्ट करें",
        btnCancel: "Back",
        btnCancel_hi: "वापस",
      },
      listItems: [
        {
          title_hi: "लिंक कॉपी करें",
          title: "Copy Link",
          icon: "mdi-link",
          action: this.copyLink,
        },
        {
          title_hi: "विडिओ को ब्लॉक करें",
          title: "Block video",
          icon: "mdi-cancel",
          action: () => {
            this.dialogBlock = true;
          },
        },
        {
          title_hi: "रिपोर्ट करें",
          title: "Report",
          icon: "mdi-flag",
          action: this.toggleDialogReport,
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      authUser: "auth/user",
    }),

    listReportItems() {
      return listReportItems.filter(
        (el) => !["inappropriate", "impersonation"].includes(el.reason)
      );
    },
  },
  watch: {},
  methods: {
    blockVideo(trueFalse) {
      //confirm before block
      if (!trueFalse) return;

      this.loading = true;
      return axios
        .post("/ugcp/block/video", {
          id: this.video.id,
        })
        .then((res) => {})
        .finally(() => {
          this.loading = false;
          this.dialogReport = false;
          this.sheet = false;
        });
    },
    toggleDialogReport() {
      this.dialogReport = !this.dialogReport;
    },
    reportVideoDialog(item) {
      this.dialogReportConfirmData.reason = item.reason;

      // this.dialogReportConfirmData.title = item.dialog_title;
      this.dialogReportConfirmData.title_hi = item.dialog_title_hi;
      this.dialogReportConfirmData.text = item.dialog_text;
      this.dialogReportConfirmData.text_hi = item.dialog_text_hi;

      this.dialogReportConfirmData.text += `<br/><br/>If you report someone or something, Vikarn doesn't tell them who reported it.`;
      this.dialogReportConfirmData.text_hi += `<br/><br/>यदि आप किसी को या किसी चीज़ की रिपोर्ट करते हैं, तो विकर्ण उन्हें यह नहीं बताता कि इसकी सूचना किसने दी।`;

      this.dialogReportConfirmData.url = item.url;

      if (item.btnOK) {
        this.dialogReportConfirmData.btnOK = item.btnOK;
      }
      if (item.btnOK_hi) {
        this.dialogReportConfirmData.btnOK_hi = item.btnOK_hi;
      }
      if (item.btnCancel) {
        this.dialogReportConfirmData.btnCancel = item.btnCancel;
      }
      if (item.btnCancel_hi) {
        this.dialogReportConfirmData.btnCancel_hi = item.btnCancel_hi;
      }

      this.dialogReportConfirm = true;
    },
    reportVideo(reason, url) {
      if (reason == "copyright-infringement") {
        Browser.open({
          url: url + `?video_id=${this.video.id}`,
        });
        return;
      }
      this.loading = true;
      return axios
        .post("/ugcp/report/video", {
          id: this.video.id,
          reason,
        })
        .then((res) => {})
        .finally(() => {
          this.loading = false;
          this.dialogReportConfirm = false;
          this.dialogReport = false;
          this.sheet = false;
        });
    },
    copyLink() {
      const text2copy = `https://app.vikarn.com/videos/${this.video.id}?ytid=${this.video.ytid}&slug=${this.video.slug}`;
      this.$clipboard(text2copy);
      navigator.clipboard.writeText(text2copy);
      this.$store.dispatch("setAlert", {
        message: "Link copied to clipboard.",
        type: "success white--text",
        icon: "mdi-check-circle",
      });
    },
  },
};
</script>

<style>
</style>